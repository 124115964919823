import {useEffect, useState} from "react";
import axios from "axios";
import {API_ENDPOINT} from "../../constance";
import Button from "./button";
import {Link} from "react-router-dom";
import useAuth from "../chooks/useAuth";

export default function NavBarUserProfiler() {

    const [userLogged, setUserLogged] = useState(false)
    const [userData, setUserData] = useState(null)
    const {authenticated, login, logout} = useAuth()

    useEffect(() => {
        console.log(localStorage)
        console.log(axios.defaults.headers)
        console.log("ACCESS TOKEN")
        console.log(localStorage.getItem('access_token'))
        if (localStorage.getItem('access_token') !== null) {
            axios.get(API_ENDPOINT + 'users/get/', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                }
            })
                .then((resp) => {
                    console.log(resp.data.user)
                    setUserData(resp.data.user)
                    setUserLogged(true)
                    localStorage.setItem('uid', resp.data.user.id)
                })
                .catch((err) => {
                        console.log(err)
                    }
                )
        }
    }, [authenticated]);

    const LogoutUser = () => {
        console.log(localStorage)
        axios.post(API_ENDPOINT + "users/logout/", {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
            refresh_token: localStorage.getItem('refresh_token',)
        }, {headers: {'Content-Type': 'application/json'}})
            .then((resp) => {
                    setUserLogged(false)
                    localStorage.clear()
                }
            )
            .catch((err) => {
                console.log(err)
            })
    }


    return (
        <div className={'p-5'}>
            {
                userLogged ? (
                    <div className={'flex gap-5 justify-end'}>
                        <Link to={'/cart'}><p><i className='bx bxs-cart'></i></p></Link>
                        <Link to={'/personal'}>
                            <p>{userData.firstname}</p>
                        </Link>

                        <button onClick={LogoutUser}><i className='bx bxs-exit'></i></button>
                    </div>
                ) : (
                    <div>
                        <Link to={'/signin'}>
                            <button className={'text-neutral-500 hover:text-black transition-all'}>
                                Войти
                            </button>
                        </Link>
                        <span>   /   </span>
                        <Link to={'/signup'}>
                            <button className={'text-neutral-500 hover:text-black transition-all'}>
                                Регистрация
                            </button>
                        </Link>
                    </div>


                )
            }
        </div>
    )
}