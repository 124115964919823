import {useEffect, useState} from "react";
import axios from "axios";
import {API_ENDPOINT, MEDIA_ROOT} from "../constance";

export default function EditableImage({imageID}) {

    const [url, setUrl] = useState(null);

    useEffect(() => {
        axios.get(API_ENDPOINT + `utils/editableImage?eid=${imageID}`)
                .then((resp) => {
                    setUrl(MEDIA_ROOT + resp.data.url)
                })
    }, [])

    return (
        <div className="editable-image w-full">
            {
                url && (
                    <img src={url} alt={imageID} className={'w-full'} />
                )
            }
        </div>
    )
}