import logo from '../logo.svg';
import '../App.css';
import '../components/product_preview'
import HeadMenu from "../components/ui/headmenu";
import Button from "../components/ui/button";
import BlogPostPreview from "../components/blogpost_preview";

import vector1 from '../images/vectors/Vector 1.svg'
import vector2 from '../images/vectors/Vector 2.svg'
import vector3 from '../images/vectors/Vector 3.svg'
import ProductPreview from "../components/product_preview";
import {useEffect, useState} from "react";
// import {Link} from "react-router-dom";
import BlogPostPreviewFullPage from "../components/ui/blogpost_preview_fullpage";
import Footer from "../components/ui/footer";
import {Link, animateScroll as scroll} from "react-scroll";
import Modal from "../components/ui/modal";
import TextInput from "../components/ui/TextInput";
import {Swiper, SwiperSlide} from 'swiper/react';
import HorizontalRow from "../components/ui/HorizontalRow";
import FadedButton from "../components/ui/buttonFaded";
import Showcase from "../components/ui/showcase";
import NewSeasonsCover from "../images/new2025.jpg"
import EditableImage from "../components/EditableImage";
import {API_ENDPOINT} from "../constance";
import axios from "axios";


function Home() {

    const [newsModalIsOpen, setNewsModalIsOpen] = useState(false)
    const [newsOkModalIsOpen, setNewsOkModalIsOpen] = useState(false)
    const [processing, setProcessing] = useState(false);
    const [newsEmail, setNewsEmail] = useState('')

    const sendNewEmail = () => {
        setProcessing(true);
        setNewsModalIsOpen(false)
        axios.post(API_ENDPOINT + "news/subscribe/", {
            email: newsEmail
        })
            .then(res => {
                setNewsModalIsOpen(false)
                setNewsOkModalIsOpen(true)
            })
            .catch(err => {
                alert("Ошибка. Попробуйте позже.")
            })
            .finally(() => {
                setProcessing(false);
            })
    }

    useEffect(() => {
        document.title = 'Deal'
        if (localStorage.getItem('newsModal') == null || Number(localStorage.getItem('newsModal')) - 86400000 >= new Date().getTime()) {
            setTimeout(() => {
                setNewsModalIsOpen(true)
                localStorage.setItem('newsModal', new Date().getTime().toString())
            }, 4000)
        }
    }, []);

    // REAL HEIGHT OF THE SCEREN
    useEffect(() => {
        const handleResize = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={'relative overflow-x-hidden'}>
            <div className={'h-screen relative'}>
                <img src={vector1} className={'absolute h-2/3 top-1/3 -z-10 max-lg:h-1/3 max-lg:top-1/4'}/>
                <img src={vector2}
                     className={'absolute end-0 w-1/2 top-1/3 -z-10 max-lg:w-2/3 max-lg:left-1/2 max-lg:top-1/4'}/>
                <img src={vector3}
                     className={'absolute w-1/2 start-[calc(100vh/8*2.5)] -z-10 -top-[100px] max-lg:h-2/3 max-lg:start-1/4 max-lg:scale-[1.7] max-lg:scale-y-[1.5] max-lg:scale-y-100 max-lg:scale-x-100 max-lg:-top-[250px]'}/>
                <div className="App container m-auto z-10">
                    <HeadMenu/>
                    <div className={'flex justify-center items-center -mt-40 h-screen max-lg:overflow-hidden'}>
                        <div>
                            <h1 className={'text-8xl max-lg:text-6xl old-font'}>DEAL</h1>
                            <h2 className={'text-3xl max-lg:xl old-font'}>Твой стиль - наше предложение</h2>
                            <Link activeClass="active"
                                  to="about"
                                  spy={true}
                                  smooth={true}
                                  offset={-70}
                                  duration={500}>
                                <Button value={'О нас'}/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'container m-auto mt-5 max-lg:p-5'}>
                <div className={'flex max-lg:flex-col justify-around gap-10'}>
                    <div className={'w-full'}>
                        <div className={'flex gap-5 max-lg:gap-2 max-lg:flex-col'}>
                            <div className={'w-1/2 max-lg:w-full aspect-square'}>
                                <EditableImage imageID={1} />
                            </div>
                            <div className={'mt-4 max-lg:mt-0 w-1/2 max-lg:w-full'}>
                                <h3 className={'text-2xl'}>Новые коллекции 2025</h3>
                                <p>Здесь вы можете ознакомиться с новыми предложениями и коллекциями этого сезона</p>
                                <div className={'w-full'}>
                                    <a href={`newest/`}>
                                        <Button value={'Смотреть новинки'}/>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className={'container m-auto mt-5 max-lg:p-5'}>
                <Showcase showcaseId={1}/>
            </div>
            <div className={'m-auto mt-5 text-center bg-about-image p-20 text-white -mb-10'} id={'about'}>
                <div className={'w-3/4 max-lg:w-full m-auto'}>
                    <h2 className={'text-3xl'}>О нас</h2>
                    <p className={'text-lg max-lg:text-sm'}>DEAL - это фэшн комьюнити, созданное командой специалистов,
                        которые считают
                        мир моды своим домом.


                    </p>
                    <p className={'text-lg mt-10 max-lg:text-sm'}>
                        Наша цель - предлагать клиентам широкий спектор люкс и премиум брендов, доступных к заказу на
                        сайте, вовлекать во Вселенную фэшн через наши социальные сети, где мы делимся своим экспертным
                        мнением и интересными фактами о моде и стиле, создавать исключительный уровень сервиса,
                        отталкиваясь от собственного багажа опыта в данной сфере.
                    </p>

                    <p className={'text-lg mt-10 max-lg:text-sm'}>
                        Добро пожаловать в DEAL
                    </p>
                </div>
            </div>
            <Footer/>

            <Modal isOpen={newsModalIsOpen} title={'Новости DEAL'} onClose={() => {
                setNewsModalIsOpen(false)
            }}>
                <p>Подпишитесь на нашу рассылку и получайте уникальные предложения и интересные новости.</p>
                <TextInput hint={'Email'} onChange={(e) => setNewsEmail(e.target.value)} hasLabel={false}/>
                <Button action={() => sendNewEmail()}>Подписаться</Button>
            </Modal>

            <Modal isOpen={newsOkModalIsOpen} title={'Получилось'} onClose={() => {
                setNewsOkModalIsOpen(false)
            }}>
                <p>Теперь вы будете получать от нас новости. Спасибо!</p>
            </Modal>

            <Modal isOpen={processing} title={'Секундочку...'} onClose={() => {}} canBeClosed={false}>
                <p>Обрабатываем данные</p>
            </Modal>
        </div>
    );
}

export default Home;
