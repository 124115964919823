import HeadMenu from "../components/ui/headmenu";
import HorizontalRow from "../components/ui/HorizontalRow";
import VerticalRow from "../components/ui/VerticalRow";
import {useEffect, useState} from "react";
import FiltersGroup from "../components/ui/FiltersGroup"
import FilterCheckBox from "../components/ui/FilterCheckBox";
import axios from "axios";
import {API_ENDPOINT} from "../constance";
import ProductPreview from "../components/product_preview";
import Button from "../components/ui/button";
import MultiRangeSlider from "../components/ui/MultyRangeSlider/MultiRangeSlider";
import {useSearchParams} from "react-router-dom";
import CatalogFilters from '../utils/CatalogFilters.json'
import Footer from "../components/ui/footer";
import {filter} from "clean-css/lib/optimizer/level-1/property-optimizers";

export default function Catalog() {

    const [filterTags, setFilterTag] = useState([])
    const [catalog, setCatalog] = useState([])
    const [catalogFilters, setCatalogFilters] = useState({})

    const [searchParams, setSearchParams] = useSearchParams();
    const [catalogContent, setCatalogContent] = useState(null)
    const [lastCatalogPage, setLastCatalogPage] = useState(null)

    const sizeOrder = ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL']

    const [catalogPage, setCatalogPage] = useState(1)
    const [moreLoader, setMoreLoader] = useState(false)
    const [currentFilters, setCurrentFilters] = useState('')

    // REAL HEIGHT OF THE SCEREN
    useEffect(() => {
        const handleResize = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const switchMobileFiltersMenu = () => {
        const m = document.querySelector('#filtersMenu');
        if (m.classList.contains('max-lg:hidden')) {
            document.body.style.overflow = "hidden"
            m.classList.remove('max-lg:hidden')
            m.style.height = `calc(var(--vh, 1vh) * 100)`;
        } else {
            document.body.style.overflow = "auto"
            m.classList.add('max-lg:hidden')
            m.style.height = "";
        }
    }

    const ToggleFilter = (tagName, state) => {
        console.log(`TOGGLE ${tagName} to ${state}`)
        let filters = filterTags

        if (state) filters.push(tagName)
        else {
            const index = filters.indexOf(tagName);
            if (index > -1) {
                filters.splice(index, 1);
            }
        }

        setFilterTag(filters)

        console.log(filters)
    }

    function GetCatalogPage() {
        setMoreLoader(true)
        const minPrice = document.querySelector('#minPriceFilter').value
        const maxPrice = document.querySelector('#maxPriceFilter').value

        let apiCall = ''


        apiCall = API_ENDPOINT + `catalog/?filters=${currentFilters}&minPrice=${minPrice}&maxPrice=${maxPrice}`


        apiCall += `&page=${catalogPage}`

        console.warn("Page calling")
        axios.get(apiCall)
            .then((resp) => {
                console.log(resp.data)
                console.log(resp.data.catalog)
                setLastCatalogPage(resp.data)
                if (catalogContent) {
                    setCatalogContent([...catalogContent, ...resp.data.catalog])
                } else {
                    setCatalogContent([...resp.data.catalog])
                }

                setMoreLoader(false)
            })
    }

    async function CollectFilters() {
        let activeFilters = []
        Object.keys(catalogFilters).forEach(
            (fgroup) => {
                Object.keys(catalogFilters[fgroup]).forEach(
                    (filter) => {
                        const f = catalogFilters[fgroup][filter]
                        const fElem = document.querySelector(`#${f.replace('.', 'dot').replace('/', 'slash')}_filter`).checked
                        if (fElem) {
                            activeFilters.push(f)
                        }
                    }
                )
            }
        )
        console.log(activeFilters)
        return activeFilters.join()
    }

    useEffect(() => {
        if (catalogPage !== 1) {
            GetCatalogPage()
        }
    }, [catalogPage])


    const GetCatalog = async (df) => {
        setCatalog(null)
        setCatalogContent(null)
        setCatalogPage(1)
        CollectFilters()
            .then((filters) => {
                    setCurrentFilters(filters)
                    const minPrice = document.querySelector('#minPriceFilter').value
                    const maxPrice = document.querySelector('#maxPriceFilter').value

                    let apiCall = ''


                    if (df !== null) {
                        apiCall = API_ENDPOINT + `catalog/?filters=${df}&minPrice=${minPrice}&maxPrice=${maxPrice}`
                    } else {
                        apiCall = API_ENDPOINT + `catalog/?filters=${filters}&minPrice=${minPrice}&maxPrice=${maxPrice}`
                    }

                    apiCall += `&page=1`
                    console.log(apiCall)

                    axios.get(apiCall)
                        .then((resp) => {
                            console.log(resp.data)
                            console.log(resp.data.catalog)
                            setCatalogContent([...resp.data.catalog])
                            setLastCatalogPage(resp.data)
                        })
                }
            )
        // alert(filters)

    }

    useEffect(() => {
        console.log(localStorage.getItem("allowScroll"))
        document.title = 'Каталог'


        axios.get(API_ENDPOINT + 'tags/groups')
            .then(async (resp) => {
                let filters = resp.data
                axios.get(API_ENDPOINT + 'sizes/')
                    .then((respSizes) => {
                        // filters['Размер'] = {}
                        //
                        // respSizes.data.sort((a, b) => sizeOrder.indexOf(a) - sizeOrder.indexOf(b)).forEach((size) => {
                        //     filters['Размер'][size] = `size_${size.replace('.', 'dot').replace('/', 'slash')}`
                        // })

                        setCatalogFilters(resp.data)

                        setCatalogContent(null)
                        setCatalog(null)
                    })
            })
    }, []);

    useEffect(() => {
        console.warn(catalogFilters)
        const dfilters = searchParams.get('df')
        console.log("FILTERS")
        console.log(dfilters)


        if (dfilters !== null) {
            dfilters.split(',').forEach((filter) => {
                const fElem = document.querySelector(`#${filter}_filter`)
                if (fElem !== null) fElem.checked = true
            })
        }
        console.warn("Calling catalog...")
        GetCatalog(dfilters)
    }, [catalogFilters]);


    return (
        <div>
            <div className="App container m-auto z-10">
                <HeadMenu/>
                <div className={'flex justify-around gap-5 max-lg:flex-col'}>
                    <div className={'w-1/4 max-lg:w-full'}>

                        <span className={'hidden max-lg:block m-auto'} onClick={switchMobileFiltersMenu}><i
                            className='bx bxs-filter-alt'></i> Фильтры</span>
                        {/* DESKTOP FILTER MENU */}
                        <div
                            id={'filtersMenu'}
                            className={'max-lg:w-full text-start max-lg:hidden max-lg:w-screen max-lg:h-full-custom max-lg:fixed max-lg:top-0 max-lg:start-0 max-lg:bg-white max-lg:overflow-auto z-50 max-lg:p-5'}>
                            <h2 className={'text-2xl'}>Фильтры</h2>
                            <span className={'hidden max-lg:block absolute top-0 end-0 m-5'}
                                  onClick={switchMobileFiltersMenu}>X</span>
                            {
                                Object.keys(catalogFilters).map((key) =>
                                    <FiltersGroup name={key}>
                                        {
                                            Object.keys(catalogFilters[key]).sort().map((filter) =>
                                                <FilterCheckBox toTitleCase={key !== "Размер"}
                                                                tag={catalogFilters[key][filter]} label={filter}
                                                                toggleAction={ToggleFilter}/>
                                            )
                                        }
                                    </FiltersGroup>
                                )
                            }

                            <fieldset>
                                <legend>Стоимость</legend>
                                <VerticalRow>
                                    <p>От</p>
                                    <input type={'number'} className={'border border-black p-2 text-neutral-500'}
                                           id={'minPriceFilter'}
                                           defaultValue={'129'}/>
                                    <p>До</p>
                                    <input type={'number'} className={'border border-black p-2 text-neutral-500'}
                                           id={'maxPriceFilter'}
                                           defaultValue={'5000000'}/>
                                </VerticalRow>

                                {/*<legend>Цена</legend>*/}
                                {/*<VerticalRow>*/}
                                {/*    <input type={'number'} placeholder={'Мин.'} className={'border border-black p-1'}/>*/}
                                {/*    <input type={'number'} placeholder={'Макс.'} className={'border border-black p-1'}/>*/}
                                {/*</VerticalRow>*/}
                            </fieldset>


                            {/*<FiltersGroup name={'Пол'}>*/}
                            {/*    <FilterCheckBox tag={'male'} label={'Для мужчин'} toggleAction={ToggleFilter}/>*/}
                            {/*    <FilterCheckBox tag={'female'} label={'Для женщин'} toggleAction={ToggleFilter}/>*/}
                            {/*</FiltersGroup>*/}

                            <div className={'block max-lg:hidden'}>
                                <Button value={'Применить фильтры'} action={() => GetCatalog(null)}/>
                            </div>

                            <div className={'hidden max-lg:block'}>
                                <Button value={'Применить фильтры'} action={() => {
                                    GetCatalog(null)
                                    switchMobileFiltersMenu()
                                }}/>
                            </div>
                        </div>
                    </div>
                    <div className={'w-3/4 max-lg:w-full text-start max-lg:p-5 catalog'}>
                        <h1 className={'text-2xl max-lg:text-xl mb-3'}>Каталог ({catalogContent && lastCatalogPage.total})</h1>
                        {
                            catalogContent ? (
                                catalogContent.length > 0 ? (
                                    <div>
                                        <div className={'grid grid-cols-4 max-lg:grid-cols-2 max-lg:grid-cols-1 gap-4'}>
                                            {
                                                catalogContent.map((d) =>
                                                    <ProductPreview productData={d}/>
                                                )
                                            }
                                        </div>
                                        {
                                            moreLoader ? (
                                                <div
                                                    className={'w-full flex justify-center items-center flex-col p-20'}>
                                                    Загружаем еще <i className='bx bx-loader-alt bx-spin'></i>
                                                </div>
                                            ) : (

                                                lastCatalogPage.page < lastCatalogPage.pages && (
                                                    <div>
                                                        <Button fullw={true} action={() => {
                                                            setCatalogPage((prev) => prev + 1)
                                                        }}>Загрузить еще</Button>
                                                    </div>
                                                )


                                            )
                                        }

                                    </div>
                                ) : (
                                    <div className={'w-full flex justify-center items-center flex-col'}>
                                        <p>Ничего не найдено</p>
                                        <p>Попробуйте поменять фильтры</p>
                                    </div>
                                )
                            ) : (
                                <div className={'w-full flex justify-center items-center flex-col'}>
                                    Загружаем каталог <i className='bx bx-loader-alt bx-spin'></i>
                                </div>
                            )

                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}